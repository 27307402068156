import { Injectable } from '@angular/core';
import { Parametro } from '../Modelo/Parametro';
import { Persona } from '../Modelo/Persona';
import { Localidad } from '../Modelo/Localidad';
import { Provincia } from '../Modelo/Provincia';
import { Estrategia } from '../Modelo/Estrategia';
import { Campaing } from '../Modelo/Campaing';
import { Email } from '../Modelo/Email';
import { DNI } from '../Modelo/DNI';
import { Score } from '../Modelo/Score';
import { environment } from '../../environments/environment';

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})



export class ServiceService {
 
  constructor(private http:HttpClient) {} 

  baseUrl=environment.baseUrl;

 

  getParametros(){
   const UrlFindAll=this.baseUrl + '/api/v1/parametros/findAll';      
   return this.http.get<Parametro[]>(UrlFindAll);
  }
  getParametrosByHash(hash:String){   
    const UrlGetParameter=this.baseUrl + '/api/v1/parametros/findByHash?hash=' + hash   ;  
    
    return this.http.get<Parametro>(UrlGetParameter);   
   }

   getProvinciaByDescripcion(provinciaDesc:String){   
    const UrlGetParameter=this.baseUrl +'/api/v1/provincias/findByDescripcion?descripcion=' + provinciaDesc   ; 
    return this.http.get<Provincia>(UrlGetParameter);   
   }

   getLocalidadByDescripcionAndCodProvincia(localidadDesc:String,codProv:String){
    const UrlGetParameter=this.baseUrl +'/api/v1/localidades/findByDescripcionAndCodProvincia?descripcion=' + localidadDesc + '&codProvincia=' + codProv   ;  
      return this.http.get<Localidad>(UrlGetParameter);   
   }

   getEstrategiaByHash(hash:String){
    const UrlGetParameter=this.baseUrl +'/api/v1/estrategias/findByHash?hash=' + hash   ;     
    return this.http.get<Estrategia>(UrlGetParameter);   
   }

   getLocalidadesByProvincia(provincia:String){    
    const UrlGetParameter=this.baseUrl + '/api/v1/localidades/findByProvincia?codProvincia=' + provincia;
    return this.http.get<Localidad[]>(UrlGetParameter);   
   }

   getAllLocalidades(){    
    //const UrlGetParameter=this.baseUrl + '/api/v1/localidades/findAll';
    const UrlGetParameter = window.location.origin + '/assets/json/allLocalidades.json'
    return this.http.get<Localidad[]>(UrlGetParameter);   
   }

   getProvincias(){   
    //const UrlGetParameter=this.baseUrl + '/api/v1/provincias/findAll';
    const UrlGetParameter = window.location.origin + '/assets/json/provincias.json'
    return this.http.get<Provincia[]>(UrlGetParameter);   
   }

 
   sendSMS(campaing:Campaing){    
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')
    const urlPostSMS=this.baseUrl + '/api/v1/validate/sms';     
    return this.http.post<any>(urlPostSMS, campaing, { headers }); 
  
   }

   validateCaptcha(token:string){      
    const UrlGetParameter=this.baseUrl + '/api/v1/validate/tokenCaptcha?g-recaptcha-response=' + token;
    return this.http.get<any>(UrlGetParameter);   
  
   }


  validateDNI(dni?:String){   
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8') 
     const urlGetDNI= this.baseUrl + '/api/v1/validate/dni'; 
     return this.http.post<DNI>(urlGetDNI,{dni},{ headers: headers,}); 
   }


   getScore(cuil:String){
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')
    const urlGetScore= this.baseUrl + '/api/v1/validate/score'; 
    return this.http.post<Score>(urlGetScore,{cuil},{ headers: headers,}); 

  }

  validateEmail(apiKey:string,emailToValidate:string){
    const urlPostSMS='https://api.zerobounce.net/v2/validate';  
    const param=new HttpParams()
    .set('api_key', apiKey)
    .set('email', emailToValidate)  
    .set('ip_address', '');   
    return this.http.get<Email>(urlPostSMS,{  params: param});  

  }

   createPersona(persona:Persona){    
    const UrlCreatePersona=this.baseUrl + '/api/v1/contactos/create';   
    return this.http.post<Persona>(UrlCreatePersona,persona);   

   }

   updatePersona(persona:Persona){ 
    const UrlUpdatePersona=this.baseUrl + '/api/v1/contactos/update';   
    return this.http.put<Persona>(UrlUpdatePersona,persona)
   }

}
