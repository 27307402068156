<div *ngIf="!loading" class="main-container">
  <section
    class="imageblock switchable feature-large height-100 height-100--zoomfix"
  >
    <!-- LEFT BLOCK -->
    <div class="imageblock__content col-lg-8 col-md-8 pos-right">
      <div
        class="background-image-holder"
        style="background-image: url('{{
          this.parametro.imagenBackgroundString
        }}');background-position: initial;opacity: 1"
      >
        <img
          src="{{ this.parametro.imagenBackgroundString }}"
          alt="Background image"
        />
      </div>
      <div style="position: absolute; top: 2%; left: 5%; width: 20%">
        <img
          *ngIf="parametro !== undefined"
          src="{{ this.parametro.imagenLogoString }}"
          class="img-responsive"
          alt="Responsive image"
        />
      </div>
      <div
        *ngIf="parametro !== undefined"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          line-height: 1.14;
          color: rgb(255, 255, 255);
          margin-bottom: 64px;
          font-size: calc(2em + 1vw);
          text-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
          font-weight: bold;
          text-align: center;
        "
      >
        {{ this.parametro.textoSobreImagen }}
      </div>
      <div
        *ngIf="parametro !== undefined"
        style="
          background-color: white;
          font-size: 11px;
          text-align: justify;
          position: absolute;
          bottom: 0%;
          width: 100%;
        "
      >
        {{ this.parametro.textoDebajoImagen }}
      </div>
    </div>

    <!-- RIGHT BLOCK -->

    <div class="col-md-4 col-lg-4" style="overflow: scroll; height: 100%">
      <div class="d-flex justify-content-center">
        <h2
          *ngIf="parametro !== undefined"
          style="color: rgb(255, 131, 0); text-align: center"
        >
          {{ this.parametro.texto }}
        </h2>
      </div>

      <!-- SI DESCOMENTO LO DE ABAO APARECE EL BOTON COMENZAR, PARA QUE SE MUESTRE ADEMAS
        LA VARIABLE EN EL TS DEBE TENER this.isFormShow = false  -->
      <!--  <div class="d-flex justify-content-center" *ngIf="isComenzarShow">
                <button mat-raised-button (click)="showForm()">Comenzar</button>
            </div>-->
      <br />

      <div *ngIf="isFormShow">
        <mat-divider></mat-divider>

        <!--   <app-step3-form  [(hash)]="hash"  [(parametro)]="parametro" [(isFormShow)]="isFormShow" (finish)="closeForm($event)"></app-step3-form>-->

        <app-step2-form
          [(hash)]="hash"
          [(parametro)]="parametro"
          [(isFormShow)]="isFormShow"
          (finish)="closeForm($event)"
          [estrategia]="estrategia"
        ></app-step2-form>
      </div>

      <div class="container">
        <div class="row d-flex justify-content-center">
          <br />
          <span class="type--fine-print" id="textoFinal"></span>
          <br />
        </div>
      </div>

      <!--end of stepper-->
      <!--fin stepper-->

      <mat-divider></mat-divider>
      <br />
      <div class="container">
        <app-text-block
          *ngIf="parametro.titulo1Formulario"
          [(titulo)]="parametro.titulo1Formulario"
          [(body)]="parametro.detalle1Formulario"
          [(style)]="subtitle"
        ></app-text-block>
        <app-text-block
          *ngIf="parametro.titulo2Formulario"
          [(titulo)]="parametro.titulo2Formulario"
          [(body)]="parametro.detalle2Formulario"
          [(style)]="body"
        ></app-text-block>
        <app-text-block
          *ngIf="parametro.titulo3Formulario"
          [(titulo)]="parametro.titulo3Formulario"
          [(body)]="parametro.detalle3Formulario"
          [(style)]="body"
        ></app-text-block>
        <app-text-block
          *ngIf="parametro.titulo4Formulario"
          [(titulo)]="parametro.titulo4Formulario"
          [(body)]="parametro.detalle4Formulario"
          [(style)]="body"
        ></app-text-block>
        <app-text-block
          *ngIf="parametro.titulo5Formulario"
          [(titulo)]="parametro.titulo5Formulario"
          [(body)]="parametro.detalle5Formulario"
          [(style)]="body"
        ></app-text-block>

        <div *ngIf="parametro.textoPieFormulario">
          <mat-divider></mat-divider>
          <br />
          <div class="row">
            <div
              class="col-sm-12 col-md-12 col-lg-12"
              style="background-color: white"
            >
              <h3
                style="
                  color: rgb(20, 96, 211);
                  font-weight: bold;
                  margin-bottom: 1rem;
                  font-size: 1rem;
                  text-align: center;
                "
              >
                {{ this.parametro.textoPieFormulario }}
              </h3>
            </div>
          </div>
        </div>
        <!--fin row-->
      </div>
    </div>
    <!--fin container-->
    <!--fin row-->
    <!--fin row-->
  </section>
</div>
<!--fin container-->
<script>
  (function (w, d, k, t, u, s, c, f) {
    f = function (t) {
      t = new Date();
      return (
        t.getFullYear() +
        "" +
        (t.getMonth() + 1) +
        "" +
        t.getDate() +
        "T" +
        t.getHours() +
        "" +
        t.getMinutes() +
        "" +
        t.getSeconds()
      );
    };
    u =
      "https://widgets-static.embluemail.com/accounts/3225EFB5A4EAD399/scripts/sw_322.js?ts=" +
      f();
    w[k] = w[k] || [];
    s = d.createElement(t);
    s.async = 1;
    s.src = u;
    c = d.getElementsByTagName(t)[0];
    c.parentNode.insertBefore(s, c);
  })(window, document, "_swdg", "script");
</script>
