import { Persona } from '../Modelo/Persona';
import { Campaing } from '../Modelo/Campaing';
import { Mensaje } from '../Modelo/Mensaje';
import { environment } from '../../environments/environment';


export class Utils {


    makeid(length:number):string {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

     getCampaing(persona:Persona, sms:string) : Campaing {
        return {
              campanaId: environment.idCampaingPortal,                  
              origen: environment.idOrigen,         
              mensajes: this.getMensajes(persona,sms),
              rutearContactosParaSms:true
        }   
       }
    
       getMensajes(persona:Persona, sms:string) : Mensaje[]{
    
        var prefijo_string = new String(persona.prefijo);
        var numero_string = new String(persona.telefono);


        return [{
         nombre:persona.nombre,
         apellido:persona.apellido,
         mensaje:"Verificacion identidad",
         telefono:prefijo_string.concat(numero_string.toString()),
         identificador:sms,
         datos: this.getCodigoVerificacion(sms),
        }
       ]
       }
     
       getCodigoVerificacion(sms:string): String[]{
        var datosSMS: Array<string>;
        datosSMS = [sms]; 
        return datosSMS;
      }

      isEmail(search:string):boolean{
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(search)
      }
    
    
}