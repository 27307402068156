import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ServiceService } from '../../../Service/service.service';
import { Parametro as Parametros } from '../../../Modelo/Parametro';
import { Persona } from '../../../Modelo/Persona';
import { Provincia } from '../../../Modelo/Provincia';
import { Localidad } from '../../../Modelo/Localidad';
import { Estrategia } from '../../../Modelo/Estrategia';
import { Campaing } from '../../../Modelo/Campaing';
import { Email } from '../../../Modelo/Email';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { DNI } from '../../../Modelo/DNI';
import { Score } from '../../../Modelo/Score';
import { Captcha } from '../../../Modelo/Captcha';

import { Subscription } from 'rxjs';
import { TermsComponent } from '../../terms/terms.component';
import { environment } from '../../../../environments/environment';
import { Utils } from '../../utils';


@Component({
  selector: 'app-step2-form',
  templateUrl: './step2-form.component.html',
  styleUrls: ['./step2-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class Step2FormComponent implements OnInit {
  @Input('parametro') parametros: Parametros;
  @Input() isFormShow: boolean;
  @Input() hash: string;
  @Output() finish = new EventEmitter<boolean>();
  @Input() estrategia: Estrategia;

  /*CONTROL DE BOTTONES
   */
  isEditable: boolean = false;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  siguiente_step1_btn: boolean;
  confirmar_step1_btn: boolean = false;
  done_btn: boolean = false;
  //reenviar_btn: boolean = false; //lo muestro
  isCelularShow: boolean;

  /*CONTROL DE VALIDACIONES
   Si OK, significa que se hizo la validacion y fue satisfactoria
  */
  DNIValidadoOK: boolean = false;
  SMSValidadoOK: boolean = false;
  EmailValidadoOK: boolean = false;
  ScoreValidadoOK: boolean = false;
  score: number = 0;
  captchaValidadoOK: boolean = false;
  localidadOK:boolean = false

  codigoVerificacion: string;

  /*Objetos necesarios*/
  persona: Persona;
  provincias: Provincia[] = [];
  provincia: Provincia;
  localidad: Localidad;
  localidades: Localidad[] = [];

  campaing: Campaing;
  email: Email;
  dni: DNI | any;
  scorePersona: Score;
  captcha: Captcha;
  scaledRecaptcha: boolean = false;

  //manejo de localidades
  filteredLocalidades: Localidad[];
  localidadInput: String;
  localidadControl : FormControl | any;
  allLocalidades: Localidad[] = []

  // Subscriptions
  private subscriptions: Subscription[] = [];

  constructor(
    private service: ServiceService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private utils: Utils
  ) {}

  ngOnInit() {
    this.persona = this.getPersona(this.estrategia.id);
    this.parametros.estrategiaId = this.persona.estrategiaId;

    if (this.parametros.habilitaProvincia) {
      const provSubscription = this.service
        .getProvincias()
        .subscribe((data) => {
          this.provincias = data;
        });

      this.subscriptions.push(provSubscription);
    }

    this.isEditable = false;
    this.siguiente_step1_btn = true;
    this.confirmar_step1_btn = false;
    this.isCelularShow = false;

    this.firstFormGroup = this._formBuilder.group({
      dni: [
        12345678,
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{7,8}'),
        ]),
      ],
      nombre: [
        'Ta',
        Validators.compose([
          Validators.required,
          Validators.pattern("[a-zA-Z ,.'-]{3,20}"),
        ]),
      ],
      apellido: [
        'NOf',
        Validators.compose([
          Validators.required,
          Validators.pattern("[a-zA-Z ,.'-]{3,20}"),
        ]),
      ],
      prefijo: [
        '2344',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{2,4}'),
        ]),
      ],
      telefono: [
        '233333',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{6,8}'),
        ]),
      ],
      email: [
        'cc@c.com',
        Validators.compose([Validators.required, Validators.email]),
      ],
      aceptaTerminos: [false, Validators.requiredTrue],
    });

    if (this.parametros.habilitaProvincia) {
      this.firstFormGroup.addControl(
        'provincia',
        new FormControl('', Validators.required)
      );
    }


    //MANEJO DE LOCALIDADES    
    if (this.parametros.habilitaLocalidad) {

      //agrego formcontrol de localidad
      this.firstFormGroup.addControl(
        'localidad',
        new FormControl('', Validators.compose([Validators.required, Validators.minLength(3)]))
      );
      
      //pedir todas las localidades
      const getAllLocalsSub = this.service.getAllLocalidades().subscribe(
        (data)=>{this.allLocalidades = data},
        (error)=>{console.log(error)}
      )

      //manejo filtrado y seteo
      const localidadSubscription = this.firstFormGroup.controls.localidad.valueChanges.subscribe((value)=>{
        this.handleLocalidad(value)
      })

      //suscripciones
      this.subscriptions.push(localidadSubscription);
      this.subscriptions.push(getAllLocalsSub);

    }


    // SECOND FORM
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });
  }


  getPersona(estrategia: number): Persona {
    return {
      id: 0,
      dni: undefined,
      nombre: undefined,
      apellido: undefined,
      prefijo: undefined,
      telefono: undefined,
      email: undefined,
      fecha: moment(new Date()).format('YYYY-MM-DD'),
      estrategiaId: estrategia,
      provincia: this.provincia,
      localidad: this.localidad,
      aceptaTerminos: false,
      scoreValidado: false,
      dniValidado: false,
      captchaValidado: false,
      emailValidado: false,
      smsValidado: false,
      score: 0,
      ip: undefined,
    };
  }

  handleProvincia(event) {
    this.firstFormGroup.controls.localidad.setValue('')
    if(!this.allLocalidades.length){
      return false
    }
    this.localidades = this.allLocalidades.filter(loc=>loc.codprovincia === event.value)
  }

  handleLocalidad(value) {
    if(!this.firstFormGroup.get('provincia').value){
      return false
    }

    const lowervalue = value.toLowerCase();

    if (this.localidades && this.localidades.length && value.length >= 3) {
      this.filteredLocalidades = this.localidades.filter((loc) =>
      loc.descripcion.toLowerCase().includes(lowervalue)
      );
      const localidad = this.localidades.find(loc=>loc.descripcion === value)
      if(localidad){
        this.localidadOK = true
        this.persona.localidad = localidad
      }else{
        this.firstFormGroup.controls.localidad.setErrors({invalid:true})
        this.localidadOK = false
      }
      
    }else{
      this.filteredLocalidades = []
    }

  }

  enviarSMS(isReenvio: boolean) {
    this.scaleRecaptcha();

    var reenviar = <HTMLInputElement>document.getElementById('reenviar');
    var telefonoreingreso = <HTMLInputElement>(
      document.getElementById('telefonoreingreso')
    );
    var telefonoreingresoLabel = <HTMLInputElement>(
      document.getElementById('telefonoreingresoLabel')
    );
    var matformcelular = <HTMLInputElement>(
      document.getElementById('matformcelular')
    );
    var matformprefijo = <HTMLInputElement>(
      document.getElementById('matformprefijo')
    );
    var prefijoreingreso = <HTMLInputElement>(
      document.getElementById('prefijoreingreso')
    );
    var prefijoingresoLabel = <HTMLInputElement>(
      document.getElementById('prefijoingresoLabel')
    );

    if (this.parametros.validaSms) {
      reenviar.hidden = true;
      prefijoreingreso.hidden = true;
      prefijoingresoLabel.hidden = true;
      telefonoreingreso.hidden = true;
      telefonoreingresoLabel.hidden = true;
      matformcelular.hidden = true;
      matformprefijo.hidden = true;
    }

    if (isReenvio) {
      if (telefonoreingreso.value != '') {
        this.persona.prefijo = prefijoreingreso.value;
        this.persona.telefono = telefonoreingreso.value;
      }
    }

    if (this.parametros.validaSms) {
      
        if (!isReenvio) {
          this.persona.prefijo = this.firstFormGroup.controls['prefijo'].value;
          this.persona.telefono = this.firstFormGroup.controls['telefono'].value;
        }

        var secondLabel = <HTMLInputElement>(
          document.getElementById('secondLabel')
        );
        secondLabel.hidden = false;

        var celularEnviado = <HTMLInputElement>(
          document.getElementById('celularEnviado')
        );
        celularEnviado.textContent =
          '¡Revisá  tus mensajes! Hemos enviado el mensaje al telefono (0' +
          this.persona.prefijo +
          ') - 15 ' +
          this.persona.telefono +
          '.';

        /**
         * Iniciar los segundos para habilitar el mensaje
         */

        this.showCountDown();
        this.codigoVerificacion = this.utils.makeid(4);

        const campaing =  this.utils.getCampaing(this.persona, this.codigoVerificacion)
        const smsSub = this.service
          .sendSMS(campaing)
          .subscribe((data) => {});

        this.subscriptions.push(smsSub);
      
    }

    if(!isReenvio){
      this.guardar(this.persona);
    }
  }

  validarEmail(persona: Persona) {

    if(this.firstFormGroup.get('email').invalid){
      return false
    }

    const subs = this.service
      .validateEmail(environment.apikey, persona.email)
      .subscribe((data) => {
        this.email = data;
        if (this.email.status == 'valid') {
          this.EmailValidadoOK = true;
        } else {
          this.EmailValidadoOK = false;
        }
        this.persona.emailValidado = this.EmailValidadoOK;
      });
    this.subscriptions.push(subs);
  }

  public handleDni(event): void {
    if (this.firstFormGroup.controls.dni.valid) {
      this.validarDNI(event.target.value);
    }
  }

  scaleRecaptcha(): void {
    if (!this.scaledRecaptcha && window.innerWidth < 450) {
      this.scaledRecaptcha = true;
      const bodyDivs = document.body.children;
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < bodyDivs.length; index++) {
        const element: any = bodyDivs[index];
        if (
          element.style.position === 'absolute' &&
          element.style.zIndex === '2000000000'
        ) {
          bodyDivs[index].className = 'scaled';
        }
      }
    }
  }

  public handleEmail(event): void {
    this.persona.email = event.target.value;
    /** Si es necesario validar email la activo al momento ya del captcha*/
    if (this.parametros.validaEmail) {
      this.validarEmail(this.persona);
    }
  }

  validarDNI(dniPersona: String) {
    var nombreElement = <HTMLInputElement>document.getElementById('nombre');
    var apellidoElement = <HTMLInputElement>document.getElementById('apellido');

        this.service
          .validateDNI(dniPersona)
          .subscribe((data) => {
            this.dni = data;

            if (this.dni.data[0] !== undefined) {
              nombreElement.value = this.dni.data[0].nombre;
              apellidoElement.value = this.dni.data[0].apellido;
              this.persona.nombre = nombreElement.value;
              this.persona.apellido = apellidoElement.value;

              nombreElement.readOnly = true;
              apellidoElement.readOnly = true;
              this.DNIValidadoOK = true;


              //busco y seteo provincia
              if(this.parametros.habilitaProvincia){
                this.provincia = this.provincias.find(prov=>{
                  return prov.descripcion.toLowerCase() === this.dni.data[0].provincia.toLowerCase()
                })
 
                if(this.provincia){
                 this.firstFormGroup.controls.provincia.setValue(this.provincia.codprovincia)
                 this.handleProvincia({value:this.provincia.codprovincia})
                 //this.persona.provincia = this.provincia;
                }

              }

              /**
               * Traigo el score si tengo que validar score
               */

              if (this.parametros.validaScore) {
                this.validarScore(
                  this.dni.data[0].cuil
                );
              }
            } else {
              //Ojo porue blanquea lo que escribo en el form
              // nombreElement.value = "";
              // apellidoElement.value = "";
              nombreElement.readOnly = false;
              apellidoElement.readOnly = false;
              this.DNIValidadoOK = false;
              this.ScoreValidadoOK = false;
            }
          });

  }

  //function to resolve the reCaptcha and retrieve a token
  async resolved(captchaResponse: string) {
    //console.log(`Resolved response token: ${captchaResponse}`);
    await this.sendTokenToBackend(captchaResponse); //declaring the token send function with a token parameter
  }

  //function to send the token to the node server
  sendTokenToBackend(tok: string) {
    //calling the service and passing the token to the service
    this.service.validateCaptcha(tok).subscribe(
      (data) => {
        // console.log(data)
        this.captchaValidadoOK = true;
      },
      (err) => {
        //  console.log(err)
        this.captchaValidadoOK = false;
      },
      () => {}
    );
  }

  validarScore(cuil: String) {
    this.service.getScore(cuil).subscribe((data) => {
      this.scorePersona = data;
      if (this.scorePersona.data !== undefined) {
        this.score = this.scorePersona.data.score;
        this.ScoreValidadoOK = true;
      } else {
        this.ScoreValidadoOK = false;
        this.score = 0;
      }
    });
  }

  handleConfirmar(){
    if (this.firstFormGroup.invalid) {
      return false;
    }

    if(this.parametros.habilitaLocalidad && !this.localidadOK){
      return false
    }

    if(this.parametros.validaSms){
      this.enviarSMS(false)
    }else{
      this.guardar(this.persona)
    }
  }

  guardar(persona: Persona) {

    this.persona.dniValidado = this.DNIValidadoOK;
    this.persona.emailValidado = this.EmailValidadoOK;

    this.persona.scoreValidado = this.ScoreValidadoOK;
    this.persona.score = this.score;

    //retardo apenas la peticion para evitar error
    setTimeout(()=>{
      this.service.createPersona(persona).subscribe(
        (data) => {
          this.persona = data;
        },
        (err) => {}
      );
    },300)
  }

  actualizar(persona: Persona) {
    // console.log(persona);

    this.service.updatePersona(persona).subscribe(
      (data) => {
        // console.log("Se actualiza persona con con id:" + persona.id);
      },
      (err) => {
        // console.log(err)
      },
      () => {}
    );
  }

  done() {
    var dniElement = <HTMLInputElement>document.getElementById('dni');
    var emailElement = <HTMLInputElement>document.getElementById('email');
    var smsElement = <HTMLInputElement>document.getElementById('sms');
    var errorLabelElement = <HTMLInputElement>(
      document.getElementById('errorLabel')
    );
    var nombreElement = <HTMLInputElement>document.getElementById('nombre');
    var apellidoElement = <HTMLInputElement>document.getElementById('apellido');
    var captchaElement = <HTMLInputElement>document.getElementById('captcha');

    this.persona.dni = dniElement.value;
    this.persona.email = emailElement.value;
    this.persona.apellido = apellidoElement.value;
    this.persona.nombre = nombreElement.value;

    var smsValido = true;

    /** Se hacen las validacion si la campaing lo requiere */

    if (this.parametros.validaSms) {
      smsValido = this.validarSmsAntesDeConfirmar(smsElement);
      this.captchaValidadoOK = true; /**Se pone true el captcha porque no requiere validacion de captcha si hay sms */
    }

    if (!smsValido) {
      errorLabelElement.textContent =
        'El código de SMS ingreso no es correcto. Envie un SMS para confirmar.';
      errorLabelElement.style.color = 'red';
      // errorLabelElement.hidden=false;
    } else if (smsValido && this.captchaValidadoOK) {
      /**
       * Asigno a persona el resultado de validaciones antes de guardar.
       */

      this.persona.dniValidado = this.DNIValidadoOK;
      this.persona.smsValidado = this.SMSValidadoOK;
      this.persona.emailValidado = this.EmailValidadoOK;
      this.persona.scoreValidado = this.ScoreValidadoOK;
      this.persona.captchaValidado = this.captchaValidadoOK;
      this.persona.score = this.score;

      if (this.parametros.validaSms == false) {
        captchaElement.hidden = true;
      }
      this.done_btn = true;

      this.actualizar(this.persona);

      this.finish.emit(true);
    } else {
      errorLabelElement.textContent =
        'El captcha no se ha validado.Ingréselo y vuelva a confirmar la operación';
      errorLabelElement.style.color = 'red';
      // errorLabelElement.hidden=false;
    }
  }

  validarSmsAntesDeConfirmar(smsElement: any): boolean {
    if (
      smsElement.value.toUpperCase() == this.codigoVerificacion.toUpperCase()
    ) {
      this.SMSValidadoOK = true;
      return true;
    } else {
      this.SMSValidadoOK = false;
      return false;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(TermsComponent);
    dialogRef.afterClosed().subscribe((result) => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  async showCountDown() {
    //Ejenmplo tomado de https://es.stackoverflow.com/questions/332813/creacion-de-timer-cuenta-regresiva

    var secondLabel = <HTMLInputElement>document.getElementById('secondLabel');
    var reenviar = <HTMLInputElement>document.getElementById('reenviar');
    var telefonoreingreso = <HTMLInputElement>(
      document.getElementById('telefonoreingreso')
    );
    var telefonoreingresoLabel = <HTMLInputElement>(
      document.getElementById('telefonoreingresoLabel')
    );
    var matformcelular = <HTMLInputElement>(
      document.getElementById('matformcelular')
    );
    var matformprefijo = <HTMLInputElement>(
      document.getElementById('matformprefijo')
    );
    var prefijoreingreso = <HTMLInputElement>(
      document.getElementById('prefijoreingreso')
    );
    var prefijoingresoLabel = <HTMLInputElement>(
      document.getElementById('prefijoingresoLabel')
    );

    var date = new Date(2020, 1, 1, 0, 1, 30);

    // Función para rellenar con ceros
    var padLeft = (n) => '00'.substring(0, '00'.length - n.length) + n;

    // Asignar el intervalo a una variable para poder eliminar el intervale cuando llegue al limite
    var interval = setInterval(() => {
      // Asignar el valor de minutos
      var minutes = padLeft(date.getMinutes() + '');
      // Asignqr el valor de segundos

      var seconds = padLeft(date.getSeconds() + '');

      // console.log(minutes, seconds);

      secondLabel.textContent =
        'en ' + minutes + ' minutos ' + seconds + ' segundos';

      // Restarle a la fecha actual 1000 milisegundos
      date = new Date(date.getTime() - 1000);

      // Si llega a 2:45, eliminar el intervalo
      if (minutes == '00' && seconds == '01') {
        // if( seconds == '01' ) {
        clearInterval(interval);
        secondLabel.hidden = true;
        reenviar.hidden = false;
        prefijoreingreso.hidden = false;
        matformprefijo.hidden = false;
        prefijoreingreso.hidden = false;
        prefijoingresoLabel.hidden = false;
        telefonoreingreso.hidden = false;
        telefonoreingresoLabel.hidden = false;
        matformcelular.hidden = false;
      }
    }, 1000);
  }


    /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

}
