import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LandingComponent } from './home/landing/landing.component';
//import { FormComponent } from './home/form/form.component';
import { Step2FormComponent } from './home/form/step2-form/step2-form.component';
import { Step3FormComponent } from './home/form/step3-form/step3-form.component';
import {HomeGuard} from './Service/guards/home.guard'
import { NomatchComponent } from './nomatch/nomatch.component';

const routes: Routes = [
  { 
  path: '', 
  component: HomeComponent,
  canActivate: [HomeGuard]
},
  // { path: 'landing', component: LandingComponent },
  // { path: 'form', component: Step3FormComponent },
  // { path: 'form2', component: Step2FormComponent },
  { path: '**', component: NomatchComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
