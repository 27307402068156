import { Component, OnInit, Input } from '@angular/core';
import {ServiceService} from '../../Service/service.service';
import {Parametro} from '../../Modelo/Parametro';
import {Persona} from '../../Modelo/Persona';
import {Localidad} from '../../Modelo/Localidad';
import {Provincia} from '../../Modelo/Provincia';
import {Estrategia} from '../../Modelo/Estrategia';
import {Campaing} from '../../Modelo/Campaing';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {MatDialog} from '@angular/material/dialog';

import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Step3FormComponent } from '../form/step3-form/step3-form.component';
import { Utils } from '../utils';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

    

  hash:string;
 
  returnUrl: any;

  /*Objetos necesarios
  */
  parametros : Parametro[];
  parametro: Parametro;
  persona: Persona;
  estrategia: Estrategia;
  provincia : Provincia;
  local:Localidad;
  campaing:Campaing;


  isFormShow: boolean;
  isComenzarShow: boolean;

  subtitle: String;
  body: String;
  
	// Subscriptions
	private subscriptions: Subscription[] = [];  

  // @Input

  constructor(private service:ServiceService,private _formBuilder: FormBuilder, public dialog: MatDialog,
    private spinner: NgxSpinnerService){  

      this.spinner.show();
      this.isFormShow = false;
      this.isComenzarShow = true;
      //inicializo para pasarle al text-block component
      this.subtitle = 'Subtitle';
      this.body = 'Body';
    
  }
  

  ngOnInit() {    
    this.parametro = new Parametro();

    this.returnUrl=window.location.search
    var newarr = this.returnUrl.split("=");     
    this.hash=newarr[1];
   
  
    const sortSubscription = this.service.getParametrosByHash(this.hash)
    .subscribe(data=> {
      this.parametro=data;            

      this.parametro.imagenBackgroundString='data:image/JPEG;base64,'+this.parametro.imagenBackground;
      this.parametro.imagenLogoString='data:image/JPEG;base64,'+this.parametro.imagenLogo;
     
       this.service.getEstrategiaByHash(this.parametro.hash)
      .subscribe(data=> {
        this.estrategia=data;           
        this.persona = this.getPersona(this.estrategia.id);     
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      })    
    })

    this.subscriptions.push(sortSubscription);
  }

  	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
 
  
  getPersona(estrategia:number) : Persona{
    return {
          id:0,
          dni:undefined,
          nombre:undefined,
          apellido:undefined,       
          prefijo:undefined, 
          telefono:undefined, 
          email:undefined,          
          fecha: moment(new Date()).format("YYYY-MM-DD"),         
          estrategiaId:estrategia ,
          provincia:this.provincia,
          localidad:this.local,
          aceptaTerminos:false,
          scoreValidado:false,
          dniValidado:false,
          captchaValidado:false,
          emailValidado:false, 
          smsValidado:false,         
          score:0,
          ip:undefined   
    }   
   }
   
  closeForm(flag: boolean) {
    var textofinalElement = <HTMLInputElement>document.getElementById('textoFinal');   
    textofinalElement.textContent="Un asesor se comunicará pronto. Gracias!."
    textofinalElement.style.color = "blue";
    textofinalElement.hidden=false;
    this.isFormShow = false;
  }

  showForm() {
    this.isFormShow = true;
  }

}