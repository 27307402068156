<div class="site-wrapper col-8">

    <div class="site-wrapper-inner">

        <div class="cover-container">

            <div class="masthead clearfix">
                <div class="inner">
                    <h3 class="masthead-brand">Logo</h3>
                    <!-- <img *ngIf="parametro!==undefined" src={{this.parametro.imagenLogoString}} class="img-responsive" alt="Responsive image" /> -->
                    <!-- <nav>
                        <ul class="nav masthead-nav">
                            <li class="active"><a href="#">Home</a></li>
                            <li><a href="#">Features</a></li>
                            <li><a href="#">Contact</a></li>
                        </ul>
                    </nav> -->
                </div>
            </div>

            <div class="inner cover">
                <h1 class="cover-heading">{{this.parametro.textoSobreImagen}}</h1>
                <p class="lead">{{this.parametro.texto}}</p>
                <p class="lead">
                    <a (click)="showForm()" class="btn btn-lg btn-default">Learn more</a>
                </p>
            </div>


        </div>
        <div class="mastfoot">
            <div class="inner">
                <p>{{this.parametro.textoDebajoImagen}}</p>
            </div>
        </div>

    </div>
</div>
<div *ngIf="!isFormShow" class="col-3 pull-right">
    <app-step3-form [(parametro)]="parametro" [(isFormShow)]="isFormShow" (finish)="closeForm($event)"></app-step3-form>
</div>