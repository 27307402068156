import {HttpClientModule} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatNativeDateModule} from '@angular/material/core';
import {DemoMaterialModule} from './stepper/material-module';

import { HomeComponent } from './home/home.component';
import { StepperComponent } from './stepper/stepper.component';
import { AppComponent } from './app.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { AddComponent } from './Persona/add/add.component';
import {ServiceService} from 'src/app/Service/service.service';
import {MatSelectModule} from '@angular/material/select';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { NgxSpinnerModule } from 'ngx-spinner';
import { TermsComponent } from './home/terms/terms.component';
import { Utils } from './home/utils';

import { Mugan86GoogleAnalyticsModule } from 'mugan86-ng-google-analytics';
import { TextBlockComponent }  from './home/text-block/text-block.component';
import { LandingComponent }  from './home/landing/landing.component';

import { AppRoutingModule }  from './app-routing.module';
import { Step2FormComponent } from './home/form/step2-form/step2-form.component';
import { Step3FormComponent } from './home/form/step3-form/step3-form.component';
import { NomatchComponent } from './nomatch/nomatch.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    StepperComponent,
    AddComponent, 
    TermsComponent,
    TextBlockComponent,
    LandingComponent,
    Step2FormComponent,
    Step3FormComponent,
    NomatchComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    DemoMaterialModule,
    MatNativeDateModule,
    ReactiveFormsModule ,
    MatSelectModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    NgxSpinnerModule, //Spinner
    AppRoutingModule,  
    Mugan86GoogleAnalyticsModule.forRoot(
      {
        analyticsId: 'UA-179389067-1',
        showLog: false
      }
    )
    
  ],
  entryComponents: [TermsComponent], 
  providers: [ServiceService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    Utils
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// TODO ver esto https://www.npmjs.com/package/ng-google-analytics