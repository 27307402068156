<div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12" style="background-color:white; ">
        <h2 style="color: rgb(255, 131, 0);font-size: 2rem; ">{{this.titulo}}</h2>
        <div *ngIf="this.subtitle">
            <h3 style="color: rgb(0, 0, 0); margin-bottom: 2rem;text-align: justify;">{{this.body}}</h3>
        </div>
        <div *ngIf="!this.subtitle">
            <h3 style="color :dimgray; margin-bottom: 2rem; font-size: 14px;text-align: justify; ">{{this.body}}</h3>
        </div>
    </div>
</div>