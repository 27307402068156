import { Component, OnInit, Input } from '@angular/core';
import {Parametro} from '../../Modelo/Parametro';


@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.css']
})
export class TextBlockComponent implements OnInit {

  @Input() titulo: String;
  @Input() body: String;
  @Input() style: String;

  subtitle: boolean;
  
  constructor() {
  }

  ngOnInit() {    
    // this.style = 'color: rgb(0, 0, 0); margin-bottom: 2rem;text-align: justify';
    if(this.style == 'Subtitle') {
      this.subtitle = true;
    } else {
      this.subtitle = false;
    }
  }  

}