import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import {ServiceService} from '../../../Service/service.service';
import {Parametro} from '../../../Modelo/Parametro';
import {Persona} from '../../../Modelo/Persona';
import {Provincia} from '../../../Modelo/Provincia';
import {Localidad} from '../../../Modelo/Localidad';
import {Estrategia} from '../../../Modelo/Estrategia';
import {Campaing} from '../../../Modelo/Campaing';
import {Email} from '../../../Modelo/Email';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import * as moment from 'moment';
import {MatSelectChange} from '@angular/material/select';
import {MatDialog} from '@angular/material/dialog';
import { DNI } from '../../../Modelo/DNI';
import { Score } from '../../../Modelo/Score';
import { Captcha} from '../../../Modelo/Captcha';

import { Subscription } from 'rxjs';
import { TermsComponent } from '../../terms/terms.component';
import { environment } from '../../../../environments/environment';
import { Utils } from '../../utils';
import { IpServiceService } from '../../../ip-service.service'; 
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-step3-form',
  templateUrl: './step3-form.component.html',
  styleUrls: ['./step3-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class Step3FormComponent implements OnInit {

  @Input() parametro: Parametro;
  @Input() isFormShow: boolean;  
  @Input() hash: string; 
  @Output() finish=new EventEmitter<boolean>();  
    
   /*CONTROL DE BOTTONES
  */
  isEditable : boolean = false;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;  
    
  siguiente_step1_btn: boolean ;
  confirmar_step1_btn: boolean = false; 
  done_btn: boolean = false; 
  //reenviar_btn: boolean = false; //lo muestro
  isCelularShow: boolean;

   /*CONTROL DE VALIDACIONES
   Si OK significa que se hizo la validacion y fue satisfactoria
  */
  DNIValidadoOK: boolean = false;
  SMSValidadoOK: boolean = false;
  EmailValidadoOK: boolean = false;
  ScoreValidadoOK: boolean = false;
  score:number=0;
  captchaValidadoOK:boolean = false;

  codigoVerificacion:string;
 
  /*Objetos necesarios
  */
  persona: Persona;
  estrategia: Estrategia;
  provincias : Provincia[];
  provincia : Provincia;
  local:Localidad;
  localidades : Localidad[];
  campaing:Campaing;
  email:Email;
  dni:DNI;
  scorePersona:Score;
  captcha:Captcha;
  scaledRecaptcha: boolean = false;
  
	// Subscriptions
  private subscriptions: Subscription[] = [];  
  
  constructor(private service:ServiceService,private _formBuilder: FormBuilder, public dialog: MatDialog,
    private utils: Utils,private http:HttpClient,private ip:IpServiceService) {
    }

  
    ngOnInit() {    

      this.service.getEstrategiaByHash(this.hash)
      .subscribe(data=> {
        this.estrategia=data;           
        this.persona = this.getPersona(this.estrategia.id);                 
        this.parametro.estrategiaId=this.persona.estrategiaId;             
      })    
      
    
  
      const provSubscription = this.service.getProvincias()
      .subscribe(data=> {
        this.provincias=data;   
  
      })
  
      this.subscriptions.push(provSubscription);
  
      this.isEditable = false;
  
      this.siguiente_step1_btn= true;
      this.confirmar_step1_btn=false;
  
      this.isCelularShow=false;
  
      this.firstFormGroup = this._formBuilder.group({
        // , Validators.pattern('[0-9]')
        dni: [27039033, Validators.compose([Validators.required, Validators.minLength(7)])],
        nombre: ['Ta', Validators.required],
        apellido: ['NOf', Validators.required],
        prefijo: ['2344', Validators.compose([Validators.required, Validators.minLength(2),Validators.maxLength(4)])],
        telefono: ['233333', Validators.compose([Validators.required, Validators.minLength(6),Validators.maxLength(8)])],
        email: ['cc@c.com', Validators.compose([Validators.required, Validators.email])],
      });
  
      if(this.parametro.habilitaProvincia) {
        this.firstFormGroup.addControl('provincia', new FormControl('', Validators.required));
      }
      if(this.parametro.habilitaLocalidad) {
        this.firstFormGroup.addControl('localidad', new FormControl('', Validators.required));
      }
  
  // SECOND FORM
      this.secondFormGroup = this._formBuilder.group({
        aceptaTerminos: ['', Validators.requiredTrue]
      });
      this.thirdFormGroup = this._formBuilder.group({
        thirdCtrl: ['', Validators.required]
      });
    }
  
      /**
     * On Destroy
     */
    ngOnDestroy() {
      this.subscriptions.forEach(el => el.unsubscribe());
    }
   
    
    getPersona(estrategia:number) : Persona{
      return {
            // dni:"",
            // nombre:"",
            // apellido:"",       
            // prefijo:"", 
            // telefono:"", 
            // email:"",
            id:0,
            dni:undefined,
            nombre:undefined,
            apellido:undefined,       
            prefijo:undefined, 
            telefono:undefined, 
            email:undefined,          
            fecha: moment(new Date()).format("YYYY-MM-DD"),         
            estrategiaId:estrategia ,
            provincia:this.provincia ,
            localidad:this.local,
            aceptaTerminos:false,
            scoreValidado:false,
            dniValidado:false,
            captchaValidado:false,
            emailValidado:false,
            smsValidado:false,    
            score:0,
            ip:undefined   
      }   
     }    
  
    changeRatio(event: MatSelectChange) {  
      
      this.service.getLocalidadesByProvincia(event.value)
      .subscribe(data=> {
        this.localidades=data;   
        })
    }
  
    enviarSMS(isReenvio: boolean ) {
     
      this.scaleRecaptcha();

        var reenviar = <HTMLInputElement>document.getElementById('reenviar');
        var telefonoreingreso = <HTMLInputElement>document.getElementById('telefonoreingreso');
        var telefonoreingresoLabel = <HTMLInputElement>document.getElementById('telefonoreingresoLabel');
        var matformcelular = <HTMLInputElement>document.getElementById('matformcelular');
        var matformprefijo = <HTMLInputElement>document.getElementById('matformprefijo');
        var prefijoreingreso = <HTMLInputElement>document.getElementById('prefijoreingreso');
        var prefijoingresoLabel = <HTMLInputElement>document.getElementById('prefijoingresoLabel');
              
       if(this.parametro.validaSms){
        reenviar.hidden=true;
        prefijoreingreso.hidden=true;
        prefijoingresoLabel.hidden=true;
        telefonoreingreso.hidden=true;
        telefonoreingresoLabel.hidden=true;
        matformcelular.hidden=true;
        matformprefijo.hidden=true;
      }
  
      if (isReenvio){
  
          if (telefonoreingreso.value!=''){    
            this.persona.prefijo = prefijoreingreso.value;
            this.persona.telefono=telefonoreingreso.value;    
          }

      } else {    
       // window.alert('ip persona' +  this.persona.ip);
        this.guardar(this.persona);
      }
    
  
      
      if (!this.secondFormGroup.controls['aceptaTerminos'].hasError('required')) {
  
        if (this.parametro.validaSms) {
  
          if (!isReenvio){
                           
         
            //console.log(this.firstFormGroup.controls['prefijo'].value);
          //  console.log(this.firstFormGroup.controls['telefono'].value);
  
            this.persona.prefijo = this.firstFormGroup.controls['prefijo'].value;
            this.persona.telefono = this.firstFormGroup.controls['telefono'].value;
          }
  
           
        
  
          var secondLabel = <HTMLInputElement>document.getElementById('secondLabel');
          secondLabel.hidden = false;
  
        
          var celularEnviado = <HTMLInputElement>document.getElementById('celularEnviado');
          celularEnviado.textContent='¡Revisá  tus mensajes! Hemos enviado el mensaje al telefono (0' + this.persona.prefijo + ') - 15 ' + this.persona.telefono + '.';
  
  
          /**
         * Iniciar los segundos para habilitar el mensaje
         */
  
            this.showCountDown();
    
          //  console.log("Se trae token sms con exito");
  
            this.codigoVerificacion = this.utils.makeid(4);       
         //   console.log("Codigo de verificacion" + this.codigoVerificacion);
  
            const subs =this.service.sendSMS(this.utils.getCampaing(this.persona, this.codigoVerificacion)).subscribe(data => {
           //   console.log("Se envio con exito sms");
            })
  
         // });
          this.subscriptions.push(subs);
        }
      }
    }
  
   
  
    validarEmail(persona:Persona){
    
      const subs = this.service.validateEmail(environment.apikey,persona.email).subscribe(data=> {
        this.email=data;   
     //   console.log(this.email.status);
        if(this.email.status=="valid"){    
          this.EmailValidadoOK=true;          
        }else{      
          this.EmailValidadoOK=false;         
        }
        this.persona.emailValidado=this.EmailValidadoOK;
  
        });
        this.subscriptions.push(subs);
    }
  
    public onChange(event: Event): void {     
      var dniElement = <HTMLInputElement>document.getElementById('dni');   
      this.validarDNI(dniElement.value);
     
    }
  
  
    public onChangeEmail(event: Event): void {     
      var emailElement = <HTMLInputElement>document.getElementById('email'); 
      this.persona.email=emailElement.value;
         /** Si es necesario validar email la activo al momento ya del captcha*/
  
      if(this.parametro.validaEmail){           
          this.validarEmail(this.persona);
      }
    }
  
    validarDNI(dniPersona:String) {
      
      var nombreElement = <HTMLInputElement>document.getElementById('nombre');
      var apellidoElement = <HTMLInputElement>document.getElementById('apellido');
  
    
    
        this.service.validateDNI(dniPersona).subscribe(data=> {      
        this.dni=data;
                 
          
              if (this.dni.data[0] !== undefined) {       
                nombreElement.value = this.dni.data[0].nombre; 
                apellidoElement.value = this.dni.data[0].apellido;
                this.persona.nombre=nombreElement.value;
                this.persona.apellido=apellidoElement.value;
  
                nombreElement.readOnly = true;      
                apellidoElement.readOnly = true;       
                this.DNIValidadoOK=true; 
              
                /**
                 * Traigo el score si tengo que validar score
                 */
  
                 if(this.parametro.validaScore){              
                   this.validarScore(this.dni.data[0].cuil);
                 }
  
              }else{              
                //Ojo porue blanquea lo que escribo en el form
                // nombreElement.value = ""; 
                // apellidoElement.value = "";
                nombreElement.readOnly = false;      
                apellidoElement.readOnly = false;
                this.DNIValidadoOK=false; 
                this.ScoreValidadoOK=false; 
             
              }
          })

    }
  
  
    //function to resolve the reCaptcha and retrieve a token
  async resolved(captchaResponse: string) {
    //console.log(`Resolved response token: ${captchaResponse}`);
    await this.sendTokenToBackend(captchaResponse); //declaring the token send function with a token parameter
  }
  
      //function to send the token to the node server
    sendTokenToBackend(tok:string){
      //calling the service and passing the token to the service
      this.service.validateCaptcha(tok).subscribe(
        data => {
         // console.log(data)   
          this.captchaValidadoOK=true;
        },
        err => {
        //  console.log(err)
          this.captchaValidadoOK=false; 
        },
        () => {}
      );        
    }
  
    validarScore(cuil:String) {
     
      this.service.getScore(cuil) .subscribe(data=> {      
        this.scorePersona=data;         
              if (this.scorePersona.data !== undefined) {             
                this.score=this.scorePersona.data.score;         
                this.ScoreValidadoOK= true;
               } else{        
                this.ScoreValidadoOK= false;     
                this.score=0;  
              }
       });    
    }
  
    guardar(persona:Persona){           
      this.persona.dniValidado=this.DNIValidadoOK;
      this.persona.emailValidado=this.EmailValidadoOK;  
      this.persona.scoreValidado=this.ScoreValidadoOK;      
      this.persona.score=this.score;

      this.service.createPersona(persona)
      .subscribe(data=> {     
        this.persona=data; 
        // console.log("Se agrego persona con exito con id:" + this.persona.id + ' estrategia id' +  this.persona.estrategiaId);  
      },
        err => {
          
        //  console.log(err)      
        },
        () => {}
      
      )
    }

    scaleRecaptcha(): void {
      if (!this.scaledRecaptcha && window.innerWidth < 450) {
        this.scaledRecaptcha = true;
        const bodyDivs = document.body.children;
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < bodyDivs.length; index++) {
          const element: any = bodyDivs[index];
          if (element.style.position === 'absolute' && element.style.zIndex === '2000000000') {
            bodyDivs[index].className = 'scaled';
          }
        }
      }
    }

  
    actualizar(persona:Persona){    
  
      //console.log(persona);    
  
      this.service.updatePersona(persona)
      .subscribe(data=> {          
        // console.log("Se actualiza persona con con id:" + persona.id);  
      },
        err => {
          
         // console.log(err)      
        },
        () => {}
      
      )
    }
  
  
    done() {
  
      var dniElement = <HTMLInputElement>document.getElementById('dni');    
      var emailElement = <HTMLInputElement>document.getElementById('email');    
      var smsElement = <HTMLInputElement>document.getElementById('sms');    
      var errorLabelElement = <HTMLInputElement>document.getElementById('errorLabel');   
      var nombreElement = <HTMLInputElement>document.getElementById('nombre');
      var apellidoElement = <HTMLInputElement>document.getElementById('apellido');
      var captchaElement = <HTMLInputElement>document.getElementById('captcha');
     
      this.persona.dni = dniElement.value;
      this.persona.email  = emailElement.value;
      this.persona.apellido=apellidoElement.value;
      this.persona.nombre=nombreElement.value;
  
      var smsValido = true;
  
     
        /** Se hacen las validacion si la campaing lo requiere */
  
  
        if(this.parametro.validaSms){       
             smsValido = this.validarSmsAntesDeConfirmar(smsElement);
        }
  
        if(!smsValido) {
  
          errorLabelElement.textContent="El código de SMS ingreso no es correcto. Envie un SMS para confirmar."
          errorLabelElement.style.color = "red";
          // errorLabelElement.hidden=false;
  
        } else if (smsValido && this.captchaValidadoOK) {            
                /**
                 * Asigno a persona el resultado de validaciones antes de guardar.
                 */
               
                this.persona.dniValidado=this.DNIValidadoOK;
                this.persona.smsValidado=this.SMSValidadoOK;
                this.persona.emailValidado=this.EmailValidadoOK;
                this.persona.scoreValidado=this.ScoreValidadoOK;
                this.persona.captchaValidado=this.captchaValidadoOK;
                this.persona.score=this.score;
                
            
                captchaElement.hidden=true;  
                this.done_btn=true;           
              
                this.actualizar(this.persona);
  
                this.finish.emit(true);
                
        }else {             
                errorLabelElement.textContent="El captcha no se ha validado.Ingréselo y vuelva a confirmar la operación"
                errorLabelElement.style.color = "red";
                // errorLabelElement.hidden=false;
        } 
    }
  
    validarSmsAntesDeConfirmar(smsElement:any):boolean {    
      if(smsElement.value.toUpperCase( )==this.codigoVerificacion.toUpperCase( )){   
        this.SMSValidadoOK = true;
        return true;  
      } else {
        this.SMSValidadoOK = false;
        return false;  
      }
    }
  
    openDialog() {
      const dialogRef = this.dialog.open(TermsComponent);
  
      dialogRef.afterClosed().subscribe(result => {  
        //console.log(`Dialog result: ${result}`);
      });
    }
  
   
  
    async showCountDown(){
  
      //Ejenmplo tomado de https://es.stackoverflow.com/questions/332813/creacion-de-timer-cuenta-regresiva
  
        var secondLabel = <HTMLInputElement>document.getElementById('secondLabel'); 
        var reenviar = <HTMLInputElement>document.getElementById('reenviar');
        var telefonoreingreso = <HTMLInputElement>document.getElementById('telefonoreingreso');
        var telefonoreingresoLabel = <HTMLInputElement>document.getElementById('telefonoreingresoLabel');
        var matformcelular = <HTMLInputElement>document.getElementById('matformcelular');
        var matformprefijo = <HTMLInputElement>document.getElementById('matformprefijo');
        var prefijoreingreso = <HTMLInputElement>document.getElementById('prefijoreingreso');
        var prefijoingresoLabel = <HTMLInputElement>document.getElementById('prefijoingresoLabel');
  
        var date = new Date(2020, 1, 1, 0, 1, 30);
  
  
      // Función para rellenar con ceros
      var padLeft = n => "00".substring(0, "00".length - n.length) + n;
      
      // Asignar el intervalo a una variable para poder eliminar el intervale cuando llegue al limite
      var interval = setInterval(() => {
      
        // Asignar el valor de minutos
        var minutes = padLeft(date.getMinutes() + "");
        // Asignqr el valor de segundos
         
        var seconds = padLeft(date.getSeconds() + "");
    
        // console.log(minutes, seconds);
  
        secondLabel.textContent="en "+ minutes + " minutos "   + seconds + " segundos";
              
        // Restarle a la fecha actual 1000 milisegundos
        date = new Date(date.getTime() - 1000);
          
        // Si llega a 2:45, eliminar el intervalo
        if(  minutes == '00' && seconds == '01' )  {  
       // if( seconds == '01' ) {     
            clearInterval(interval);      
             secondLabel.hidden=true;
             reenviar.hidden=false;
             prefijoreingreso.hidden=false;
             matformprefijo.hidden=false;
             prefijoreingreso.hidden=false;
             prefijoingresoLabel.hidden=false;
             telefonoreingreso.hidden=false;
             telefonoreingresoLabel.hidden=false;
             matformcelular.hidden=false;
             
        }
        
      }, 1000);
  
    
    }
  }
