import { Component, OnInit, Input } from '@angular/core';
import { ServiceService } from '../Service/service.service';
import { Parametro } from '../Modelo/Parametro';
import { Persona } from '../Modelo/Persona';
import { Localidad } from '../Modelo/Localidad';
import { Provincia } from '../Modelo/Provincia';
import { Estrategia } from '../Modelo/Estrategia';
import { Campaing } from '../Modelo/Campaing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  hash: string;

  /*Objetos necesarios
   */
  parametros: Parametro[];
  parametro: Parametro;
  persona: Persona;
  estrategia: Estrategia;
  local: Localidad;
  provincia: Provincia;
  campaing: Campaing;

  isFormShow: boolean;
  isComenzarShow: boolean;

  subtitle: String;
  body: String;

  loading: boolean = true;
  // Subscriptions
  private subscriptions: Subscription[] = [];

  // @Input

  constructor(
    private service: ServiceService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.spinner.show();
    //this.isFormShow = true; //muestro el formulario de entrada
    this.isComenzarShow = false; //muestro el formulario de entrada
    //inicializo para pasarle al text-block component
    this.subtitle = 'Subtitle';
    this.body = 'Body';
  }

  ngOnInit() {
    this.parametro = new Parametro();

    const paramSubscription = this.route.queryParams.subscribe((params) => {
      this.hash = params['hash'];
    });

    const sortSubscription = this.service
      .getParametrosByHash(this.hash)
      .subscribe(
        (data) => {
        this.parametro = data;

        this.parametro.imagenBackgroundString =
          'data:image/JPEG;base64,' + this.parametro.imagenBackground;
        this.parametro.imagenLogoString =
          'data:image/JPEG;base64,' + this.parametro.imagenLogo;
        
        this.estrategia = data['estrategia']
        this.persona = this.getPersona(this.estrategia.id);
        this.parametro.estrategiaId = this.persona.estrategiaId;

        setTimeout(() => {
          this.isFormShow = true;
          this.loading = false;
          this.spinner.hide();
        }, 700);

      },
      (error)=>{
        console.log('error al cargar hash')
        this.loading = false;
        this.spinner.hide();
        this.router.navigate(['/nomatch'])
      }
      );

    this.subscriptions.push(sortSubscription);
    this.subscriptions.push(paramSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  getPersona(estrategia: number): Persona {
    return {
      id: 0,
      dni: undefined,
      nombre: undefined,
      apellido: undefined,
      prefijo: undefined,
      telefono: undefined,
      email: undefined,
      fecha: moment(new Date()).format('YYYY-MM-DD'),
      estrategiaId: estrategia,
      provincia: this.provincia,
      localidad: this.local,
      aceptaTerminos: false,
      scoreValidado: false,
      dniValidado: false,
      captchaValidado: false,
      emailValidado: false,
      smsValidado: false,
      score: 0,
      ip: undefined,
    };
  }

  showForm() {
    this.isComenzarShow = false;
    this.isFormShow = true;
  }

  closeForm(flag: boolean) {
    var textofinalElement = <HTMLInputElement>(
      document.getElementById('textoFinal')
    );
    textofinalElement.textContent = 'Un asesor se comunicará pronto. Gracias!.';
    textofinalElement.style.color = 'blue';
    textofinalElement.hidden = false;
    this.isFormShow = false;
  }
}
