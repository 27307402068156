import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard implements CanActivate {
  hash: String = '';
  suscription: Subscription;

  constructor(private router: Router) {
    const urlParams = new URLSearchParams(window.location.search);
    this.hash = urlParams.get('hash');
  }

  canActivate():boolean {
    if (this.hash) {
      return true;
    } else {
      this.router.navigate(['/nomatch']); //cae en componente noMatch
      return false;
    }
  }
}
