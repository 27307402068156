export class Parametro{
    id:number;
    texto:String;
    parametroTexto:String;
    habilitaNombre:Boolean;
    habilitaApellido:Boolean;
    habilitaLocalidad:Boolean;
    habilitaProvincia:Boolean;   
    imagenBackground:ArrayBuffer;
    imagenLogo:ArrayBuffer;
    imagenBackgroundString:String;
    imagenLogoString:String;
    hash:String;
    validaSms:Boolean;  
    validaEmail:Boolean;  
    validaScore:Boolean;  
    validaDni:Boolean;  
    textoSobreImagen:String;
    textoDebajoImagen:String;
    textoPieFormulario:String;
    titulo1Formulario:String;
    detalle1Formulario:String;
    titulo2Formulario:String;   
    detalle2Formulario:String;
    titulo3Formulario:String;   
    detalle3Formulario:String;
    titulo4Formulario:String;   
    detalle4Formulario:String;
    titulo5Formulario:String;   
    detalle5Formulario:String;
    estrategiaId:number;
    estrategia:any
}