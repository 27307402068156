
<!-- Stepper-->
<!-- https://stackblitz.com/edit/material-angular-stepper?embed=1&file=src/app/app.component.html -->
<mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [editable]="isEditable" [stepControl]="firstFormGroup" errorMessage="Datos son requeridos." label="Completa tu informacion personal ">
        <form [formGroup]="firstFormGroup">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>DNI</mat-label>
                <input [(ngModel)]="persona.dni" matInput placeholder="DNI" required formControlName="dni" id="dni" (change)="onChange($event)">
            </mat-form-field>
            <div *ngIf="this.parametro.habilitaNombre">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Nombre</mat-label>
                    <input [(ngModel)]="persona.nombre" matInput placeholder="Nombre" required formControlName="nombre" id="nombre">
                </mat-form-field>
            </div>
            <div *ngIf="this.parametro.habilitaApellido">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Apellido</mat-label>
                    <input [(ngModel)]="persona.apellido" matInput placeholder="Apellido" required formControlName="apellido" id="apellido" >
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-8 col-lg-6">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Prefijo</mat-label>
                        <input [(ngModel)]="persona.prefijo" matInput placeholder="Prefijo Celular" required formControlName="prefijo" id="prefijo">
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-6 ">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Celular</mat-label>
                        <input [(ngModel)]="persona.telefono" matInput placeholder="Número" required formControlName="telefono" id="telefono">
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>E-Mail</mat-label>
                <input  [(ngModel)]="persona.email"  matInput placeholder="Email" required formControlName="email" id="email" (change)="onChangeEmail($event)">
            </mat-form-field>
            
            <div *ngIf="this.parametro.habilitaProvincia">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Provincia</mat-label>
                    <mat-select formControlName="provincia" name="provincia" id="provincia" (selectionChange)=changeRatio($event) [(ngModel)]="persona.provincia">

                        <mat-option  *ngFor="let provincia of provincias" [value]="provincia.codprovincia ">
                            {{provincia.descripcion}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="this.parametro.habilitaLocalidad">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Localidad</mat-label>
                    <mat-select formControlName="localidad" name="localidad" id="localidad" [(ngModel)]="persona.localidad">
                        <mat-option *ngFor="let localidad of localidades" [value]="localidad.id">
                            {{localidad.descripcion}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="justify-content-center">
                <div class="d-flex justify-content-center">
                    <span class="type--fine-print ">Los campos marcados con (*) son requeridos</span>
                </div>

                <div class="d-flex justify-content-center">
                    <mat-label id="textoValidacion"></mat-label>
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <button mat-raised-button [disabled]="confirmar_step1_btn" matStepperNext>Confirmar</button>
                </div>

            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup" [editable]="isEditable" label="Aceptar los terminos y condiciones">

        <form [formGroup]="secondFormGroup">
            <div class="justify-content-center">
                <div class="terminos">  
                    <div class="d-flex justify-content-center">
                        <button  type="button" mat-button (click)="openDialog()" ><h4 style="color: rgb(20, 96, 211);font-weight: bold; margin-bottom: 1rem;font-size: 1rem; text-align: center; ">Leer términos y condiciones</h4></button>
                    </div>
               </div>

                <div class="d-flex justify-content-center">
                    <mat-checkbox class="example-margin"   formControlName="aceptaTerminos" [(ngModel)]="persona.aceptaTerminos" required>Aceptar Términos y Condiciones</mat-checkbox>
                </div>
                <br>

                <div *ngIf="parametro.validaSms">
                    <div class="d-flex justify-content-center">
                        <button mat-raised-button (click)="enviarSMS(false)" matStepperNext>Confirmar</button>
                    </div>
                </div>

                <div *ngIf="parametro.validaSms==false">
                    <div class="d-flex justify-content-center">
                        <button mat-raised-button (click)="guardar(persona)" matStepperNext>Confirmar</button>
                    </div>
                </div>
                
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="thirdFormGroup" [editable]="isEditable" label="Validamos tu identidad">
        <form [formGroup]="thirdFormGroup" action=" " id='demo-form'>
            <div class="justify-content-center">
                <div *ngIf="this.parametro.validaSms">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Ingrese codigo SMS</mat-label>
                        <input matInput formControlName="thirdCtrl" placeholder="Ingrese codigo SMS" id="sms" required>
                    </mat-form-field>

 

                    <div class="alert alert-primary" role="alert">
                        <span id="celularEnviado"></span> Si no recibiste el código o el número de celular es incorrecto podés reenviar el SMS <span id="secondLabel"></span>
                  
                        <mat-form-field id='matformprefijo' appearance="outline" class="example-full-width">
                            <mat-label id='prefijoingresoLabel'>Prefijo</mat-label>
                            <input  matInput placeholder="Prefijo sin el 0"  id="prefijoreingreso">                      
                           
                        </mat-form-field>

                        <mat-form-field id='matformcelular' appearance="outline" class="example-full-width">
                            <mat-label id='telefonoreingresoLabel'>Reingresar celular si anterior no es correcto</mat-label>                                           
                            <input  matInput placeholder="Número celular sin el 15"  id="telefonoreingreso">
                        </mat-form-field>
                 
                        <button mat-button id="reenviar"  (click)="enviarSMS(true)">Reenviar SMS</button>
                    </div>
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <!-- 
                        the SITE_KEY is the sitekey from your admin console.
                        use form control for validation
                      -->
                    <re-captcha (resolved)="resolved($event)" formControlName="thirdCtrl" id="captcha" siteKey="6Le15sYZAAAAAH2LCPBa2DbENKabu_7so5Oqox5p">
                    </re-captcha>
                </div>
                <br>

                <div class="d-flex justify-content-center">
                    <button mat-raised-button id="done" [disabled]="done_btn" (click)="done()" matStepperNext>Confirmar</button>
                </div>
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <br>
                        <span class="type--fine-print" id="errorLabel"></span>
                        <br>
                    </div>
                </div>
            </div>
        </form>
    </mat-step>
</mat-horizontal-stepper>
